// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import * as go from 'gojs'
import DateRangePicker from 'daterangepicker'
import jQuery from 'jquery'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Chart from 'chart.js/auto';

require("@popperjs/core")
import "bootstrap"
import { Tooltip, Popover } from "bootstrap"

// Load CSS
import '@coreui/coreui-pro';
import {type} from "chroma-js/src/utils";
require("../stylesheets/application.scss")
const coreui = require('@coreui/coreui-pro')

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {

    // Send to global
    window.coreui = coreui;

    // Both of these are from the Bootstrap 5 docs
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })

    // Init daterangepicker
    document.querySelectorAll('input[role=daterangepicker]').forEach(el => {
        let data = {
            autoUpdateInput: false,
            minDate: el.dataset.min,
            maxDate: el.dataset.max,
            locale: {
                format: 'YYYY-MM-DD',
                cancelLabel: 'Очистить'
            }
        }

        // Если передали флаг выбора времен, добавляем выбор времени
        if(el.dataset.time) {
            data.timePicker = true
            data.timePicker24Hour = true
            data.locale.format = 'YYYY-MM-DD HH:mm'
        }

        // Заглушка для коллбэка. Если передали, то используем переданный, иначе заглушку
        let callback = function(start, end, label) {}
        if(el.dataset.callback) {
            callback = window[el.dataset.callback];
        }
        jQuery(el).daterangepicker(data, callback);
    });
    $('input[role="daterangepicker"]').on('apply.daterangepicker', function(ev, picker) {
        // Если задан параметр выбора со временем, форматируем дату со временем, иначе просто дату
        let format = $(this).data('time') ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
        $(this).val(picker.startDate.format(format) + ' - ' + picker.endDate.format(format));
    }).on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });


    // Datepicker
    document.querySelectorAll('input[role=datepicker]').forEach(el => {
        let data = {
            singleDatePicker: true,
            autoUpdateInput: true,
            minDate: el.dataset.min,
            maxDate: el.dataset.max,
            locale: {
                format: 'YYYY-MM-DD',
                cancelLabel: 'Очистить'
            }
        }

        // Заглушка для коллбэка. Если передали, то используем переданный, иначе заглушку
        let callback = function(start, end, label) {}
        if(el.dataset.callback) {
            callback = window[el.dataset.callback];
        }
        jQuery(el).daterangepicker(data, callback);
    });



    // Render bot flow chart if present on a page
    if(document.querySelector("#efficiencyFlowChart") && window.gon.flowdata) {

        let diagram = go.GraphObject.make(go.Diagram, "efficiencyFlowChart", {})

        diagram.nodeTemplate =
            new go.Node("Auto")  // the Shape will go around the TextBlock
                .add(new go.Shape("RoundedRectangle")
                    // Shape.fill is bound to Node.data.color
                    .bind("fill", "color"))
                .add(new go.TextBlock({ margin: 8}) // Specify a margin to add some room around the text
                    // TextBlock.text is bound to Node.data.key
                    .bind("text", "key"));

        diagram.linkTemplate = new go.Link()
            .add( new go.Shape().bind('strokeWidth', 'stroke') )
            .add( new go.Shape({ toArrow: "Standard" }))
            .add( new go.TextBlock().bind('text', 'text') )

        diagram.model = new go.GraphLinksModel(
            window.gon.flowdata.nodes.map( x => ({ key: x.name, color: "lightblue" }) ),
            window.gon.flowdata.links.map( x => ({ from: x.source, to: x.target, text: x.value, stroke: x.stroke }) ));

    }


    // Render charts if needed
    if(Array.isArray(window.gon.charts)) {
        window.gon.charts.forEach(e => {

            let canvas = document.getElementById(e.container);
            let ctx = canvas.getContext("2d");
            let gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, 'rgba(64,243,118,1)');
            gradient.addColorStop(1, 'rgba(206,231,255,1)');
            e.config.data.datasets[0].backgroundColor = gradient;

            new Chart(
                document.getElementById(e.container),
                e.config
            );
        });
    }

    // Remote form to add a comment to a recipient
    // Works on:
    // callouts#show
    // recipients#show
    // bots#recipients
    function triggerShow(event) {
        console.log('clicked')
        event.target.classList.toggle('d-none')
        event.preventDefault()
        document.getElementById("comment-form-" + event.target.dataset.id).classList.toggle('d-none')
    }

    function triggerCancel(event) {
        event.preventDefault()
        const recipientId = event.target.dataset.id
        document.getElementById("comment-form-" + recipientId).classList.toggle('d-none')
        document.getElementById("toggle-comment-form-" + recipientId).classList.toggle('d-none')
    }

    function successSave(event) {
        const recipientId = event.target.dataset.id
        const [_data, _status, xhr] = event.detail;
        event.target.classList.toggle('d-none')
        const trigger = document.getElementById("toggle-comment-form-" + recipientId)
        trigger.classList.toggle('d-none')
        trigger.innerHTML = _data.data.comment.length > 0 ? _data.data.comment : "Добавить"
    }

    const triggers = document.querySelectorAll('.toggle-comment-form')
    triggers.forEach(trigger => {
        // Убираем предыдущий listener, потому что в turbolinks на одну и ту же форму может быть навешано несколько одинаковых событий
        trigger.removeEventListener('click', triggerShow)
        trigger.addEventListener('click', triggerShow)
    })

    const cancelTriggers = document.querySelectorAll('.cancel-comment-form')
    cancelTriggers.forEach(trigger => {
        trigger.removeEventListener('click', triggerCancel)
        trigger.addEventListener('click', triggerCancel)
    })

    const forms = document.querySelectorAll('.recipient-comment-form')
    forms.forEach(form => {
        form.removeEventListener('ajax:success', successSave)
        form.addEventListener("ajax:success", successSave);
    })


})

Rails.start()
Turbolinks.start()
ActiveStorage.start()

